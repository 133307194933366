import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/home',
    meta: {
      title: '武汉市文物监管品市场地摊预定'
    },
    component: () => import('@/pages/index.vue'),
    children: [
      {
        path: 'home',
        name: 'home',
        meta: {
          title: '武汉市文物监管品市场地摊预定'
        },
        component: () => import('@/pages/home/Home.vue')
      },
      {
        path: 'activity',
        name: 'activity',
        meta: {
          title: '活动'
        },
        component: () => import('@/pages/chooseStore/ActivityPage.vue')
      },
      {
        path: 'choose',
        name: 'choose',
        meta: {
          title: '摊位选购'
        },
        component: () => import('@/pages/chooseStore/Choose2.vue')
      },
      {
        path: 'shop',
        name: 'shop',
        meta: {
          title: '商铺选购'
        },
        component: () => import('@/pages/shop/index.vue')
      },
      {
        path: 'mine',
        name: 'mine',
        meta: {
          title: '我的'
        },
        component: () => import('@/pages/mine/Mine.vue')
      },
      {
        path: 'self-stall',
        name: 'SelftStall',
        meta: {
          title: '我的摊位'
        },
        component: () => import('@/pages/mine/StallOrderList/index.vue')
      },
      {
        path: 'self-store',
        name: 'SelftStore',
        meta: {
          title: '我的商铺'
        },
        component: () => import('@/pages/mine/StoreOrderList/index.vue')
      },
      {
        path: 'water',
        name: 'Water',
        meta: {
          title: '水费充值'
        },
        component: () => import('@/pages/mine/Water/index.vue')
      },
      {
        path: 'electric',
        name: 'Electric',
        meta: {
          title: '水费充值'
        },
        component: () => import('@/pages/mine/Electric/index.vue')
      },
      {
        path: 'payrecord',
        name: 'PayRecord',
        meta: {
          title: '交易记录'
        },
        component: () => import('@/pages/mine/PayRecord/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import('@/components/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title: '注册'
    },
    component: () => import('@/components/Register.vue')
  },
  {
    path: '/order',
    name: 'order',
    meta: {
      title: '确认订单'
    },
    component: () => import('@/components/Order.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    meta: {
      title: '商铺详情'
    },
    component: () => import('@/pages/mine/StoreOrderList/detail.vue')
  },
  {
    path: '/waterPay',
    name: 'WaterPay',
    meta: {
      title: '缴纳水费'
    },
    component: () => import('@/pages/mine/Water/waterPay.vue')
  },
  {
    path: '/electricPay',
    name: 'ElectricPay',
    meta: {
      title: '缴纳电费'
    },
    component: () => import('@/pages/mine/Electric/electricPay.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
