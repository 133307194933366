<template>
  <van-tabbar route fixed v-model="active" active-color="#ee0a24" inactive-color="#000">
    <van-tabbar-item replace :to="{name: 'home'}" icon="wap-home">首页</van-tabbar-item>
    <van-tabbar-item replace :to="{name: 'activity'}" icon="shopping-cart">摊位选购</van-tabbar-item>
    <van-tabbar-item replace :to="{name: 'shop'}" icon="shop">商铺选购</van-tabbar-item>
    <van-tabbar-item replace :to="{name: 'mine'}" icon="manager">个人中心</van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data () {
    return {
      active: 'homePage'
    }
  }
}
</script>
