<template>
  <div id="choose">
    <van-loading v-if="loading" type="spinner" color="#1989fa" style="text-align:center;margin-top:100px;" />
    <div v-show="!loading">
      <section class="flex seat-head">
        <div class="flex align-center">
          <img src="../../assets/images/unselected.png" class="store-img">
          <span class="text">可选</span>
        </div>
        <div class="flex align-center">
          <img src="../../assets/images/bought.png" class="store-img">
          <span class="text">出租中</span>
        </div>
        <div class="flex align-center">
          <img src="../../assets/images/selected.png" class="store-img">
          <span class="text">选中</span>
        </div>
        <div class="flex align-center">
          <img src="../../assets/images/overdue.png" class="store-img">
          <span class="text">暂失效</span>
        </div>
      </section>
      <div class="box">
        <section class="background">
          <section v-for="(item, index) in sideList" :key="index">
            <div>
              <p class="text-middle" style="text-align:center;margin:5px 0;">通<span class="npps"></span>道</p>
              <div class="qu">
                <p class="text-middle" style="text-align: center;margin-right:10px;">{{item.rw}}区</p>
                <div class="col">
                  <ul class="rw">
                    <li class="item" v-for="i in filtersarr(item.children, 1)" :key="i.id">
                      <div v-show="i.id" class="inner-seat flex justify-conter align-center" :class="dealseat(i.twstatus)" @click="choose(i)">
                        <span class="text">{{i.rw}}-{{i.co}}</span>
                      </div>
                    </li>
                  </ul>
                  <ul class="rw">
                    <li class="item" v-for="i in filtersarr(item.children, 2)" :key="i.id">
                      <div v-show="i.id" class="inner-seat flex justify-conter align-center" :class="dealseat(i.twstatus)" @click="choose(i)">
                        <span class="text">{{i.rw}}-{{i.co}}</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <p class="text-middle" style="text-align: center;margin-left:10px;">{{item.rw}}区</p>
              </div>
            </div>
          </section>
        </section>
        <section class="door">
          <p class="text-middle" style="text-align:center; margin-bottom:10px;" id="start">市场大门</p>
          <div class="door-rw">
            <ul style="display: flex;flex-direction: column;justify-content: flex-end;">
              <li class="item" v-for="i in singleList('I')" :key="i.id">
                <div v-show="i.id" class="inner-seat flex justify-conter align-center" :class="dealseat(i.twstatus)" @click="choose(i)">
                  <span class="text">{{i.rw}}-{{i.co}}</span>
                </div>
              </li>
            </ul>
            <p class="door-text text-middle" style="margin-top:85px;">通<span style="margin-top: 40px;display: inherit;"></span>道</p>
            <ul class="beside">
              <li class="item" v-for="i in filtersarr(singleList('J'), 1)" :key="i.id" @click="choose(i)">
                <div v-show="i.id" class="inner-seat flex justify-conter align-center" :class="dealseat(i.twstatus)">
                  <span class="text">{{i.rw}}-{{i.co}}</span>
                </div>
              </li>
            </ul>
            <ul class="beside">
              <li class="item" v-for="i in filtersarr(singleList('J'), 2)" :key="i.id" @click="choose(i)">
                <div v-show="i.id" class="inner-seat flex justify-conter align-center" :class="dealseat(i.twstatus)">
                  <span class="text">{{i.rw}}-{{i.co}}</span>
                </div>
              </li>
            </ul>
            <p class="text-middle" style="margin-top:85px;">通<span style="margin-top: 40px;display: inherit;"></span>道</p>
            <ul class="outside">
              <li class="item" v-for="i in singleList('K')" :key="i.id">
                <div v-show="i.id" class="inner-seat flex justify-conter align-center" :class="dealseat(i.twstatus)"  @click="choose(i)">
                  <span class="text">{{i.rw}}-{{i.co}}</span>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
    <!-- <van-submit-bar
      :price="itemArr.length * 100"
      :decimal-length="0"
      :suffix-label="'个摊位'"
      button-text="下一步"
      @submit="onSubmit"
      class="submit-box"
    > -->
      <!-- <van-checkbox v-model="checked" @click="checkAll">全选</van-checkbox> -->
      <!-- <template #tip>
        注：待审核期间协商商铺价格与出售时间
      </template> -->
    <!-- </van-submit-bar> -->
    <van-popup
      v-model="show"
      :overlay="false"
      round
      closeable
      :lock-scroll="false"
      class="pop"
      @close="closedialog"
      position="bottom"
      :style="{ height: '50%' }">
      <van-notice-bar
        left-icon="volume-o"
        wrapable
        :text="noticebarOption.text"
      />
      <!-- <div v-if="popData.twdesc" class="text pop-text">{{ popData.twdesc }}</div> -->
      <section v-if="popData.twyaoqiu" class="flex pop-box">
        <div class="time-box">
          <div class="text">{{ popData.twyaoqiu }}</div>
          <!-- <p class="text">结束日期：{{ popData.endtime }}</p> -->
          <!-- <p class="text-m">¥ {{ fmoney(popData.twprice, 2) }}</p> -->
        </div>
      </section>
      <section>
        <van-cell-group style="height: 4rem; overflow-y: auto;">
          <van-cell v-for="(i, index) in activityList" :key="index">
            <template #title>
                <section>
                  <div class="text-m">
                    <span class="mr-20">{{ i.name }}：</span>
                    <span>{{ saleTime(i) }}</span>
                    <div>
                      {{ i.twname }}
                      <span>￥{{ fmoney(i.price, 2) }}</span>
                    </div>
                  </div>
                </section>
            </template>
            <template #extra>
              <div class="flex justify-end align-center">
                <!-- <span class="mr-10">周</span><van-switch v-model="i.checked" class="mr-10" /><span>月</span> -->
                <!-- <van-stepper v-model="i.round" theme="round" :max="max" button-size="22" disable-input /><span class="ml-10">/周</span> -->
                <!-- <van-stepper v-model="i.round" theme="round" :max="max" button-size="22" disable-input /><span class="ml-10"></span> -->
                <van-checkbox v-model="i.checked" :disabled="i.status === false" ref="checkboxes" />
              </div>
            </template>
          </van-cell>
        </van-cell-group>
      </section>
      <!-- <van-submit-bar :price="Number(totalPrice)" button-text="提交订单" v-click-with-auth-login="onSubmit" /> -->
      <van-submit-bar button-text="提交订单" v-click-with-auth-login="onSubmit" />
    </van-popup>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      storeArray: [],
      list: [],
      sideList: [],
      doorList: [],
      item: [],
      itemArr: [],
      itemid: this.$route.query.id,
      popData: {},
      isChoose: false,
      show: false,
      noticebarOption: {
        text: ''
      },
      maxChooseCount: 0,
      alreadyCount: 0,
      totalPrice: 0,
      max: 0,
      popDataList: {},
      activityList: [],
      activityid: ''
    }
  },
  created () {
    this.activityid = this.$route.query.activityid
    this.initData()
    // this.getMaxLimit()
    // this.initPage()
  },
  watch: {
    show (val) {
      // console.log('watch', val)
      if (val) {
        this.noticebarOption.text = '选择旺铺后，请在下方点击提交订单，周六周日购票仅限当周使用。如需购买月票或办理退款请联系客服'
      }
    },
    popDataList: {
      handler (nv) {
        if (nv) {
          this.activityList = Array.from(Object.values(nv)).flat()
        }
      },
      deep: true
    }
  },
  computed: {
    saleTime () {
      return (data) => {
        if (data.startTime === data.endTime) {
          return data.startTime
        } else {
          return data.startTime + '至' + data.endTime
        }
      }
    }
  },
  mounted () {
    this.initPage()
  },
  methods: {
    initPage () {
      const door = document.querySelector('.door')
      const background = document.querySelector('.background')
      if (window.orientation === 180 || window.orientation === 0) {
        // console.log(door.classList.contains('doorpadding-y'))
        if (door.classList.contains('doorpadding-y')) {
          door.classList.remove('doorpadding-y')
        }
        if (!door.classList.contains('doorpadding-x')) {
          door.classList.add('doorpadding-x')
        }
        if (background.classList.contains('bgpadding-y')) {
          background.classList.remove('bgpadding-y')
        }
        if (!background.classList.contains('bgpadding-x')) {
          background.classList.add('bgpadding-x')
        }
      }
      if (window.orientation === 90 || window.orientation === -90) {
        if (door.classList.contains('doorpadding-x')) {
          door.classList.remove('doorpadding-x')
        }
        if (!door.classList.contains('doorpadding-y')) {
          door.classList.add('doorpadding-y')
        }
        if (!background.classList.contains('bgpadding-y')) {
          background.classList.add('bgpadding-y')
        }
        if (background.classList.contains('bgpadding-x')) {
          background.classList.remove('bgpadding-x')
        }
      }
      window.addEventListener('onorientationchange' in window ? 'orientationchange' : 'resize', function () {
        if (window.orientation === 180 || window.orientation === 0) {
          if (door.classList.contains('doorpadding-y')) {
            door.classList.remove('doorpadding-y')
          }
          if (!door.classList.contains('doorpadding-x')) {
            door.classList.add('doorpadding-x')
          }
          if (background.classList.contains('bgpadding-y')) {
            background.classList.remove('bgpadding-y')
          }
          if (!background.classList.contains('bgpadding-x')) {
            background.classList.add('bgpadding-x')
          }
        }
        if (window.orientation === 90 || window.orientation === -90) {
          if (door.classList.contains('doorpadding-x')) {
            door.classList.remove('doorpadding-x')
          }
          if (!door.classList.contains('doorpadding-y')) {
            door.classList.add('doorpadding-y')
          }
          if (!background.classList.contains('bgpadding-y')) {
            background.classList.add('bgpadding-y')
          }
          if (background.classList.contains('bgpadding-x')) {
            background.classList.remove('bgpadding-x')
          }
        }
      }, false)
    },
    initData () {
      this.loading = true
      this.$axios.post('/gw/tanweiinfos', {
        activityid: this.activityid
      }).then(res => {
        if (res.data.code === 200) {
          this.loading = false
          this.storeArray = res.data.data
          this.list = this.sort_pro(this.storeArray, ['rw'])
          this.sideList = this.list.filter(item => item.rw !== 'I' && item.rw !== 'J' && item.rw !== 'K')
          this.doorList = this.list.filter(item => item.rw === 'I' || item.rw === 'J' || item.rw === 'K')
        } else {
          this.$toast({
            type: 'fail',
            message: res.data.msg
          })
          setTimeout(() => {
            this.$router.push({
              name: 'activity'
            })
          }, 0)
        }
      }).catch((err) => {
        this.loading = false
        this.$toast({
          type: 'fail',
          message: err
        })
      })
    },
    getMaxLimit () {
      this.$axios.get('/gw/tanweixulimit').then(async res => {
        if (res.data.code === 200) {
          this.max = res.data.data
        }
      })
    },
    singleList (type) {
      if (this.doorList.length > 0) {
        const arr = this.doorList.filter(item => item.rw === type)
        // console.log(arr)
        return arr[0].children
      }
    },
    sort_pro (data, keys = []) { // keys可以传一个数组
      var c = []
      var d = {}
      for (var element of data) {
        let elementKeyStr = ''
        const elementKey = []
        const elementkeyObj = {}
        for (var key of keys) {
          elementKey.push(element[key])
          elementkeyObj[key] = element[key]
        }
        elementKeyStr = elementKey.join('_')
        if (!d[elementKeyStr]) {
          c.push({
            ...elementkeyObj,
            children: [element]
          })
          d[elementKeyStr] = element
        } else {
          for (var ele of c) {
            const isTrue = keys.some(key => {
              return ele[key] !== element[key]
            })
            if (!isTrue) {
              ele.children.push(element)
            }
          }
        }
      }
      return c
    },
    filtersarr (arr, type) {
      if (type === 1) {
        arr = arr && arr.filter(item => Number(item.co) % 2 !== 0)
      } else {
        arr = arr && arr.filter(item => Number(item.co) % 2 === 0)
      }
      return arr
    },
    dealseat (status) {
      if (status === '1') {
        return 'unselected-seat'
      } else if (status === '2') {
        return 'bought-seat'
      } else if (status === '3') {
        return 'overdue-seat'
      } else {
        return 'selected-seat'
      }
    },
    async choose (item) {
      // console.log(this.item)
      const result = await this.canPay()
      if (result === 1) {
        return
      } else if (result.type === 3) {
        this.$toast({
          duration: 3000,
          type: 'info',
          message: `暂未到出售时间，请在${result.time}期间进行选购`
        })
        return
      }
      if (item.twstatus === '2' || item.twstatus === '3') return
      // const current = this.itemArr.find(i => i === item.id)
      const hasItem = Object.prototype.hasOwnProperty.call(this.popDataList, `${item.rw}-${item.id}`)
      // 如果是已选的，再点击就删除掉
      if (hasItem) {
        // this.popDataList.delete(`${item.rw}-${item.id}`)
        this.$delete(this.popDataList, `${item.rw}-${item.id}`)
        item.twstatus = '1'
        if (this.popDataList.size < 1) this.show = false
      } else {
        if (Object.keys(this.popDataList)?.length < this.maxChooseCount) {
          const result = await this.getActivityList(item.rw, item.id)
          // console.log(result, 'result')
          if (!result) {
            setTimeout(() => {
              this.initData()
            }, 1000)
            return false
          }
          item.twstatus = '0'
          this.show = true
        } else {
          this.$toast({
            type: 'info',
            message: this.alreadyCount === 0 ? `一次最多只能租赁${this.maxChooseCount}个摊位哦` : `已租赁过${this.alreadyCount}个摊位，当前只能再租赁${this.maxChooseCount}个摊位哦`
          })
          return false
        }
        // console.log(this.popDataList)
        // const arr = [...this.popDataList.values()]
        // console.log(arr)
        // for (const [key, value] of this.popDataList.entries()) {
        //   console.log(key, value, '---')
        // }
        // for (const value of this.popDataList) {
        //   console.log(11111)
        //   console.log(value.values())
        // }
        // console.log(this.popDataList.entries())
        // console.log(1111)
        // this.popDataList.forEach((value, key) => {
        //   console.log(2222)
        //   console.log(key, value)
        // })
        // console.log(3333)
        // console.log(this.popDataList)
        // console.log(this.popDataList.entries())
        // console.log(this.activityList)
        // const values = Object.values(this.popDataList)
        // console.log(values)
        // this.activityList = Array.from(Object.values(this.popDataList.entries))
        // console.log(this.activityList)
      }
    },
    getActivityList (area, id) {
      return new Promise(resolve => {
        this.$axios.post('/gw/tanweiactivitys', { qu: area, tw: id, activityid: this.activityid }).then(res => {
          if (res.data.code === 200) {
            this.$set(this.popDataList, `${area}-${id}`, res.data.data)
            resolve(true)
          } else {
            resolve(false)
            this.$toast({
              type: 'fail',
              message: res.data.msg
            })
          }
        })
      })
    },
    canPay () {
      return new Promise(resolve => {
        this.$axios.get('/gw/startend2').then(async res => {
          if (res.data.data) {
            // 在启动范围内
            const result = await this.canChoose()
            if (!result) { // 如果返回 false 就 return
              resolve(1)
            }
            resolve(2) // 如果返回 true 就 继续
          } else {
            // 不在销售时间范围内
            resolve({
              type: 3,
              time: res.data.depart
            })
          }
        })
      })
    },
    async canChoose () {
      const isCanChoose = await this.check()
      return new Promise(resolve => {
        if (!isCanChoose) {
          this.$toast(`最多购买${this.maxChooseCount}个店铺`)
          resolve(false)
        } else if (isCanChoose === 'fail') {
          this.$toast({
            type: 'fail',
            message: '请先登录'
          })
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1000)
          resolve(false)
        } else {
          resolve(true)
        }
      })
    },
    check () {
      return new Promise((resolve) => {
        this.$axios.post('/gw/buycount', {
          buytype: '1'
        }).then(res => {
          if (res.data.code === 200) {
            // console.log(this.itemArr)
            // 已购数量， 限制数量
            const { alreadynum, limitnum } = res.data.data
            this.alreadyCount = alreadynum
            this.maxChooseCount = alreadynum === 0 ? limitnum : (limitnum - alreadynum)
            if (res.data.data && this.itemArr.length > this.maxChooseCount) {
              resolve(false)
            } else {
              resolve(true)
            }
          } else {
            resolve('fail')
          }
        })
      })
    },
    pushData (item) {
      this.popData.starttime = item.starttime
      this.popData.endtime = item.endtime
      this.popData.twdesc = item.twdesc
      this.popData.twyaoqiu = item.twyaoqiu
      this.popData.twprice = item.twprice
      if (this.itemArr.length > 1) {
        this.totalPrice += item.twprice
      } else {
        this.totalPrice = item.twprice
      }
    },
    closedialog () {
      this.item.twstatus = '1'
      this.itemArr.map(item => {
        this.item.map(i => {
          if (i.id === item) {
            i.twstatus = '1'
          }
        })
      })
      this.itemArr = []
      this.item = []
      this.show = false
    },
    groupActivitiesByTwId (data) {
      return Object.values(data.reduce((acc, item) => {
        // 如果没有这个 twId 的分组，则初始化它
        if (!acc[item.twId]) {
          acc[item.twId] = { tanweiid: item.twId, activities: [] }
        }
        // 只保留 id 和 name 字段
        acc[item.twId].activities.push({ id: item.id, name: item.name, activityType: item.activityType })
        return acc
      }, {}))
    },
    async onSubmit () {
      console.log(this.activityList)
      const noChecked = this.activityList.every(item => !item.checked)
      if (noChecked) {
        this.$toast({
          type: 'info',
          message: '请选择活动再提交'
        })
        return false
      }
      const filterChecked = this.activityList.filter(item => item.checked)
      const result = this.groupActivitiesByTwId(filterChecked)
      console.log(result, '最后选择结果')
      const res = await this.pushBuyCycle(result)
      if (res) {
        this.$router.push({
          name: 'order',
          query: {
            id: res
          }
        })
      } else {
        this.initData()
      }
      // this.$axios.post('/gw/wxphone').then(async res => {
      //   const { code, msg } = res.data
      //   // this.$router.push({
      //   //   name: 'order',
      //   //   query: {
      //   //     id: this.itemArr.toString()
      //   //   }
      //   // })
      //   if (code === 200) {
      //     const result = await this.pushBuyCycle(this.item)
      //     if (result) {
      //       this.$router.push({
      //         name: 'order',
      //         query: {
      //           id: result
      //         }
      //       })
      //     }
      //   } else {
      //     this.$toast({
      //       type: 'fail',
      //       message: msg
      //     })
      //   }
      // })
    },
    pushBuyCycle (checkedArr) {
      return new Promise((resolve) => {
        // let checked = ''
        // checkedArr.map(item => {
        //   checked += `${item.id}_${item.round},`
        // })
        this.$axios.post('/gw/index', { tanweiids: checkedArr }).then(res => {
          const { code, msg, batchid } = res.data
          if (code === 200) {
            resolve(batchid)
          } else {
            this.$toast({
              type: 'fail',
              message: msg
            })
            resolve(false)
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scope>
#choose{
  .van-popup__close-icon--top-right {
    top: 38px!important;
  }
}
.box{
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  -webkit-overflow-scrolling:touch;
  // padding-bottom: 1rem;
  border: 2px solid #ddd;
  display: flex;
  margin-bottom: 1rem;
}
.background{
  height: 100vh;
  background: url(../../assets/images/background.png) no-repeat;
  background-size: 100% 100%;
}
.bgpadding-x {
  padding-top: 3em;
  padding-left: 3em;
  padding-right: 7em;
  padding-bottom: 7.5em;
}
.bgpadding-y {
  padding-top: 3em;
  padding-left: 3em;
  padding-right: 7em;
  padding-bottom: 11.8em;
}
.doorpadding-x{
  margin-top: 1.1em;
    margin-left: -5em;
}
.doorpadding-y{
  margin-top: 1.1em;
  margin-left: -5em;
}
.door {
  transform:rotate(52deg);
  height: 300px;
}
.door-rw{
  display: flex;
}
.qu{
  display: flex;
  align-items: center;
}
.col{
  display: flex;
  flex-direction: column;
}
.rw{
  display: flex;
  list-style:none inside;
  flex-direction: row-reverse;
}
.beside{
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
}
.outside{
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
}
.item{
  .text{
    text-align: center;
    font-size: 10px;
  }
}
.store-img{
  width: 20px;
  height: 20px;
}
.seat-head{
  padding: 10px;
  padding-top: 20px;
  div {
    margin-right: 10px;
    img{
      margin-right: 5px;
    }
  }
}
.inner-seat{
  width: 44px;
  height: 36px;
}
.bought-seat{
  background: url(../../assets/images/bought.png) no-repeat;
  background-size: 100% 100%;
}
.selected-seat{
  background: url(../../assets/images/selected.png) no-repeat;
  background-size: 100% 100%;
}
.unselected-seat{
  background: url(../../assets/images/unselected.png) no-repeat;
  background-size: 100% 100%;
}
.overdue-seat{
  background: url(../../assets/images/overdue.png) no-repeat;
  background-size: 100% 100%;
}
.pop-text{
  text-align:center;
  margin:15px;
}
.time-box{
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #f012249d;
  background-image: linear-gradient(to bottom , #f012243f, #f012244f);
}
.pop-box{
  padding: 10px;
}
.clearfix:after{
  content:"";
  display:block;
  visibility:hidden;
  clear:both;
}
.npps{
  display: inline-block;
  margin-left: 110px;
}
</style>
