<template>
  <div>
    <van-swipe :autoplay="3000" class="swipe-box">
      <van-swipe-item>
        <img src="../../assets/images/banner.jpg" />
      </van-swipe-item>
    </van-swipe>
    <template v-if="activityData.length">
      <div class="card-warp" v-for="item in activityData" :key="item.id">
        <div class="card-content">
          <div class="card-message">
            <div class="card-message-left">
              <span class="activity-name">{{ item.name }}</span>
              <span class="flex align-center">
                <van-icon name="underway-o" style="margin-top: 1px;" />
                <span class="ml-10">{{ activityTime(item) }}</span>
              </span>
            </div>
            <van-button
              color="linear-gradient(to right, #ff6034, #ee0a24)"
              round
              type="info"
              size="small"
              @click="handleSubmit(item)">立即购票</van-button>
          </div>
        </div>
      </div>
    </template>
    <van-empty v-else description="暂无活动" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      activityData: []
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    initData () {
      this.$axios.get('/gw/twas').then(res => {
        this.activityData = res.data.data
      })
    },
    handleSubmit (item) {
      this.$router.push({
        name: 'choose',
        query: {
          activityid: item.id
        }
      })
    },
    activityTime (item) {
      const { endTime, startTime } = item
      if (startTime === endTime) {
        return startTime
      } else {
        return `${startTime} 至 ${endTime}`
      }
    }
  }
}
</script>

<style lang="less" scoped>
.swipe-box {
  width: 100vw;
  height: 4rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.card-warp {
  padding: 0px 0.2rem;
  margin-top: 0.2rem;
  .card-content {
    background: #ffffff;
    border-radius: 0.08rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
}
.card-title {
  padding: 0.4rem 0.2rem;
  .title {
    font-size: 0.36rem;
  }
}
.card-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem;
  .card-message-left {
    display: flex;
    flex-direction: column;
    font-size: 0.28rem;
  }
  .activity-name {
    font-size: 0.3rem;
    margin-bottom: 0.2rem;
    font-weight: 600;
  }
}
</style>
