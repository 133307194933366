<template>
  <div id="login-page">
    <r-header :isLeftArrow="true" @back="back"></r-header>
    <div v-if="loginType !== 'wx'" class="container">
      <p class="login-title">登  录</p>
      <van-form @submit="onSubmit" ref="form" :show-error-message="false">
        <van-field
          size="large"
          v-model="phone"
          name="phone"
          label="用户名"
          type="digit"
          placeholder="请输入手机号"
          :rules="[{ required: true, message: '请输入正确的手机号', pattern }]"
          class="cell"
        />
        <van-field
          size="large"
          v-model="passwd"
          name="passwd"
          label="密码"
          type="password"
          placeholder="请输入密码"
          :rules="[{ required: true, message: '请输入密码/密码可为数字或字母，长度不低于6位', validator: asyncValidator }]"
          class="cell"
        />
        <!-- <van-field
          v-model="sms"
          type="digit"
          center
          label="短信验证码"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <van-button :disabled="!show" size="small" type="primary" native-type="button" @click="sendCode">
              <span v-if="show">发送验证码</span>
              <span v-else>重新发送{{ count > 0 ? `${count}s` : '' }}</span>
            </van-button>
          </template>
        </van-field> -->
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">登录</van-button>
        </div>
        <div class="flex justify-between align-center">
          <p class="text-l text-center ml-20 text-main-color" @click="checkType('wx')">微信登录</p>
          <p class="register">还没有账号，<router-link :to="{name: 'register'}">去注册</router-link></p>
        </div>
      </van-form>
    </div>
    <div v-else>
      <div class="head-img">
        <img
          src="../assets/images/banner.jpg"
          class="mine-avatar"
        />
      </div>
      <div class="marginTop wx-box">
        <van-button round block type="primary" @click="wxLogin">微信授权登录</van-button>
        <p class="text text-center mt-20" @click="checkType('phone')">手机号码登录</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import RHeader from '@/components/RHeader.vue'
export default {
  components: {
    RHeader
  },
  name: 'login',
  data () {
    return {
      loginType: 'phone',
      phone: '',
      passwd: '',
      sms: '',
      count: '',
      show: true,
      timer: null,
      patternPwd: /^[A-Za-z\d@$!%*?&]{6,16}$/,
      pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    }
  },
  methods: {
    ...mapMutations(['saveToken', 'saveUsername', 'savePhone', 'saveNickName']),
    onSubmit () {
      // this.$axios.post('/gw/smslogin', {
      //   phone: this.phone,
      //   smsmsg: this.sms
      // }).then(res => {
      //   const data = res.data
      //   if (data.code === 200) {
      //     this.saveToken({ Authorization: data.token })
      //     this.saveUsername({ username: data.username })
      //     this.savePhone(data.phone)
      //     this.saveNickName(data.nickname)
      //     this.$toast({
      //       type: 'success',
      //       message: '登录成功'
      //     })
      //     this.$router.push({
      //       name: 'home'
      //     })
      //   } else {
      //     this.$toast({
      //       type: 'fail',
      //       message: res.data.msg
      //     })
      //   }
      // })
      this.$axios.post('/gw/passLogin', {
        phone: this.phone,
        passwd: this.passwd
      }).then(res => {
        const data = res.data
        if (data.code === 200) {
          this.saveToken({ Authorization: data.token })
          this.saveUsername({ username: data.username })
          this.savePhone(data.phone)
          this.saveNickName(data.nickname)
          this.$toast({
            type: 'success',
            message: '登录成功'
          })
          this.$router.push({
            name: 'home'
          })
        } else {
          this.$toast({
            type: 'fail',
            message: res.data.msg
          })
        }
      })
    },
    back () {
      this.$router.push({
        name: 'home'
      })
    },
    wxLogin () {
      this.isWXBrowser = this.isWXBrowser()
      if (this.isWXBrowser) {
        const code = this.getUrlCode('code')
        if (code) {
          this.checkType()
          this.servieLogin(code) // 后台登录
        } else {
          this.getWeChatCode() // 微信授权
        }
      }
    },
    isWXBrowser () {
      return (
        String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) ===
        'micromessenger'
      )
    },
    // 截取地址栏code
    getUrlCode (name) {
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [null, ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
    // 访问微信地址，用来获取code
    getWeChatCode () {
      // let local = encodeURIComponent(window.location.href); // 获取当前页面地址作为回调地址
      const local = 'http://www.whwwsc.com/mine' // 获取当前页面地址作为回调地址
      const appid = 'wx63c208bb195251ca'
      // 通过微信官方接口获取code之后，会重新刷新设置的回调地址【redirect_uri】
      window.location.href =
        'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
        appid +
        '&redirect_uri=' +
        local +
        '&response_type=code&scope=snsapi_userinfo#wechat_redirect'
    },
    checkType (type) {
      this.loginType = type
    },
    sendCode () {
      this.$refs.form.validate('phone').then(valid => {
        console.log(valid)
        if (!valid) {
          this.$axios.post('/gw/sendmsg', {
            phone: this.phone
          }).then(res => {
            const data = res.data
            if (data.code === 200) {
              this.sendBtn()
            } else {
              this.$toast({
                type: 'fail',
                message: res.data.msg
              })
            }
          }).catch((err) => {
            this.$toast({
              type: 'fail',
              message: err
            })
          })
        }
      })
    },
    sendBtn () {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    asyncValidator (val) {
      return new Promise((resolve) => {
        // this.$toast.loading('验证中...')
        setTimeout(() => {
          // this.$toast.clear()
          resolve(this.patternPwd.test(val))
        }, 1000)
      })
    }
  }
}
</script>
<style lang="less">
#login-page{
  align-items: center;
  justify-content: center;
  .container{
    .login-title{
      font-size: 30px;
      padding-top: 50px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .cell{
    line-height:35px;
    font-size:16px;
  }
  .register{
    font-size: 16px;
    color: #999;
    text-align: right;
    padding-right: 25px;
    span {
      color: cadetblue;
    }
  }
}
.marginTop {
  margin-top: 2rem;
}
.wx-box {
  padding: 0 0.75rem;
}
.head-img{
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mine-avatar{
  margin-top: 30px;
  width: 1.5rem;
  height: 1.5rem;
  object-fit: cover;
  border-radius: 50px;
  align-self: center;
  margin-bottom: 10px;
}
</style>
